// 作業可能なブックの一覧

<template lang="pug">
.my-editables

  section
    h2.text-3xl.font-bold.text-teal-700.lg__text-center 作業中のブック

    p.mt-10(v-if='!books') 読み込み中です...

    p.mt-10(v-else-if='books.length === 0') 作業中のブックはありません。

    ul.books.mt-5.lg__mt-10.mx-auto(v-else)
      li.mt-3.first__mt-0(v-for='item in books')
        book-info(
          type='edit',
          :name='item.bookTemplateName',
          :organization='item.organization',
          :specification='item.specification',
          :child='item.childName',
          :created-at='item.createdAt',
          :edit-limit-at='item.expiresAt',
          @clickCreateOrEdit='clickEditBook(item)'
        )

</template>

<script>
import { mapActions } from 'vuex'

import getEditableBooksApi from '../../api/users/get-editable-books'
import BookInfo from '../../components/book-info'
import errorCodes from '../../errors/codes'

export default {
  name: 'MyEditables',

  components: {
    BookInfo
  },

  data () {
    return {
      books: null
    }
  },

  mounted () {
    this.init()
  },

  methods: {
    ...mapActions({
      setError: 'app/setError'
    }),

    async init () {
      const response = await getEditableBooksApi()
      if (!response.ok) {
        this.setError({
          code: errorCodes.GET_EDITABLE_BOOKS_ERROR
        })
        return
      }
      this.books = response.payload.items
    },

    // ブックの編集ボタン押下
    clickEditBook (item) {
      this.$router.push({
        name: 'EditorMain',
        params: { bookId: item.id }
      })
    }
  }
}
</script>

<style lang="sass" scoped>
.my-editables
  .books
    max-width: 800px
</style>
